import * as React from "react"
import { Link } from "gatsby"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"

const AreasAtuacaoPage = () => (
  <>
    <h1>Hi from Áreas de Atuação</h1>
    <Link to="/">Go back to the homepage</Link>
  </>
)

export const Head = () => <Seo title="Áreas de Atuação" />

export default AreasAtuacaoPage
